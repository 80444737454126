<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Predict the sign of the entropy change,
        <stemble-latex content="$\Delta\text{S,}$" />
        for each of the following reactions:
      </p>

      <p>
        <chemical-latex content="a) Na+(aq) + Cl-(aq) -> NaCl(s)" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p>
        <chemical-latex content="b) 2 Fe(s) + 3/2O2(g) -> Fe2O3(s)" />
      </p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>

      <p>
        <chemical-latex content="c) 2 C6H14(l) + 19 O2(g) -> 12 CO2(g) + 14 H2O(g)" />
      </p>

      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="pl-8">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value" />
          <stemble-latex :content="option.expression" />
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question294',
  components: {StembleLatex, ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options1: [
        {expression: '$\\Delta\\text{S is positive}$', value: 'Spos'},
        {expression: '$\\Delta\\text{S is negative}$', value: 'Sneg'},
        {
          expression: '$\\text{More information is needed}$',
          value: 'unknown',
        },
      ],
    };
  },
};
</script>
